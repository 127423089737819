<template>
  <el-table-column
      fixed="right"
      label="Actions"
      width="175">
    <template slot-scope="scope">
      <el-dropdown>
        <el-button
            round
            type="primary"
            size="small"
            class="actions">
          ...
        </el-button>

        <el-dropdown-menu slot="dropdown">
          <template
              v-for="action in actions">
            <el-dropdown-item
                v-if="isVisible(action, scope.row)"
                :key="action.label"
                :divided="action.divided">
              <el-popconfirm
                  v-if="action.onConfirm"
                  v-bind="{...defaultConfirmProps, ...action.confirmProps}"
                  @confirm="action.onConfirm">
                <span
                    slot="reference"
                    :class="action.class"><i :class="action.icon" />{{ action.label }}</span>
              </el-popconfirm>

              <span
                  v-else
                  :class="action.class"
                  @click="action.onClick(scope.row)"><i
                      v-if="action.icon"
                      :class="action.icon" />{{ action.label }}</span>
            </el-dropdown-item>
          </template>
        </el-dropdown-menu>
      </el-dropdown>
    </template>
  </el-table-column>
</template>

<script>
export default {
  name: "ActionsColumn",
  props: { actions: { type: Array, default: () => [] } },
  computed: {
    defaultConfirmProps() {
      return {
        title: "Hey, You forgot to add a title.",
        icon: "el-icon-info",
        iconColor: "red",
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      }
    }
  },

  methods: {
    isVisible(action, row) {
      return action.if ? action.if(row) : true
    }
  }
}
</script>
<style lang="scss" scoped>
.danger {
  color: #f56c6c;
}

.actions {
  background: white;
  border: 1px solid var(--main-light-grey);
  color: var(--main-primary);
  box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.1);
  margin: 0.25em;
  font-size: 1.1em;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2em;
  width: 4em;
  line-height: 1em;
  padding: 0;
  transition: var(--main-transition);

  ::v-deep  span {
    flex: 1;
    padding-bottom: 0.5em;
  }

  &:hover,
  &:active,
  &:focus {
    background: var(--main-primary-dark);
    color: white;
    border-color: var(--main-primary-dark);
    box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.5);
  }
}
</style>
