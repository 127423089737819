<template>
  <div class="flex flex-wrap">
    <div
        v-for="searchOrFilter in searchOrFiltersWithValues"
        :key="searchOrFilter.urlKey"
        class="active-filter flex-center"
        :class="`${searchOrFilter.queryType}`">
      <core-text
          :key="`${searchOrFilter.urlKey}:${getFilterValue(searchOrFilter)}`"
          truncate
          bold>
        {{ searchOrFilter.label||searchOrFilter.type }}: {{ getFilterValue(searchOrFilter) }}
      </core-text>

      <i
          class="el-icon-close"
          @click="onActiveFilterClose(searchOrFilter)" />
    </div>

    <core-text
        v-if="!searchOrFiltersWithValues.length"
        class="hint-text"
        
        hint>
      No {{ queryType |pluralize }} applied
    </core-text>

    <div
        v-if="searchOrFiltersWithValues.length"
        class="flex-center">
      <core-text

          class="clear-all-filters"
          bold
          clickable
          tabindex="0"
          role="button"
          :aria-roledescription="`Clear all `"
          @click="onClear()"
          @keyup.enter.native="onClear()">
        Clear all {{ queryType | pluralize }}
      </core-text>
    </div>
  </div>
</template>

<script>
import get from "lodash/get"
import isDate from "lodash/isDate"
import isArray from "lodash/isArray"
import startCase from "lodash/startCase"
import pluralize from "pluralize"
import models from "@/Modules/OdysseyModels"
import { time } from "@/Modules/Core/utils/formatters"

export default {
  name: "CoreDataTableSearchOrFilterTags",
  filters: {
    pluralize: function(value) {
      return startCase(pluralize(value))
    }
  },
  props: {
    searchOrFiltersWithValues: { type: Array, default: () => [] },
    queryType: { type: String, default: "search" },
    onActiveFilterClose: { type: Function, required: true }
  },

  data() {
    return {
      fetchingEntities: []
    }
  },

  methods: {
    handleDateValue(value, type = "date") {
      switch (type) {
        case "datetime":
        case "datetimerange":
          return time.DATE_AND_TIME(value)

        case "date":
        case "dates":
        case "daterange":
        default:
          return time.DATE(value)
      }
    },
    onClear() {
      this.searchOrFiltersWithValues.map(this.onActiveFilterClose)
    },
    getFilterValue(searchOrFilter) {
      const { handleDateValue } = this
      const { filterValue: value, inputType, valueLabel, props } = searchOrFilter

      if (inputType === "entity-select") {
        const ids = isArray(value) ? value : [value]

        return ids
          .map(id => {
            const record = models[props.entity].find(id)

            if (!this.fetchingEntites) {
              this.fetchingEntites = []
            }

            const fetchedIds = this.fetchingEntites

            const shouldFetchRecord = () => {
              const fetchHasStarted = fetchedIds.includes(id)

              if (fetchHasStarted) {
                return false
              }

              return !record
            }

            if (shouldFetchRecord()) {
              models[props.entity].api().fetchById(id)

              fetchedIds.push(id)
            }

            return get(record, "valueList.label") || id
          })
          .join(", ")
      }

      const shouldUseDateHandler = isArray(value) ? value.filter(isDate).length : isDate(value)

      if (shouldUseDateHandler) {
        return isArray(value)
          ? value.map(v => handleDateValue(v, inputType)).join(" to ")
          : handleDateValue(value, inputType)
      }

      return valueLabel ? valueLabel() : value
    }
  }
}
</script>

<style lang="scss" scoped>
.active-filter {
  border-radius: 3px;
  padding: var(--padding-xs) var(--padding-s);
  max-width: 200px;
  margin-left: var(--margin-s);
  margin-top: var(--margin-xs);
  background-color: #fdf6ec;
  border: 1px solid #faecd8;
  color: #e6a23c;

  &.filter {
    background-color: #f0f9eb;
    border-color: #e1f3d8;
    color: #67c23a;
  }

  i {
    margin-left: var(--margin-s);
    cursor: pointer;
  }
}

.hint-text {
  padding-left: var(--padding-m);
}

.clear-all-filters {
  margin-left: var(--margin-s);
  color: #409eff;
}
</style>
