<template>
  <div class="flex-column field">
    <core-text
        capitalize
        tag="label">
      {{ labelText }}
    </core-text>

    <component
        :is="component"
        :id="`search-and-filter-${filterOrSearch.label}`"
        ref="input"
        :key="label"
        v-model.lazy="filterOrSearch.filterValue"
        v-bind="filterOrSearch.props"
        class="field ghost-element-loading"
        size="small"
        collapse-tags	
        :type="filterOrSearch.inputType" />
  </div>
</template>

<script>
import getFormField from "../FormBuilder.v2/ui/getFormField"
import set from "lodash/set"
import unset from "lodash/unset"
import isArray from "lodash/isArray"
import isDate from "lodash/isDate"
import debounce from "lodash/debounce"

export default {
  name: "CoreDataTableSearchOrFilter",
  filters: {
    pluralize: function(value) {
      return pluralize(value)
    }
  },
  props: {
    filterOrSearch: { type: Object, default: () => {} },
    label: { type: String, default: null }
  },
  data() {
    return {
      debouncedChangeRoute: null,
      debouncedOnChange: null
    }
  },
  computed: {
    labelText() {
      const { isMulti, queryType, label } = this.filterOrSearch

      const queryTypePluralization = isMulti ? pluralize(queryType) : queryType

      return this.label || `${label} ${queryTypePluralization}`
    },
    component() {
      return getFormField(this.filterOrSearch.inputType)
    },
    value() {
      return this.filterOrSearch.filterValue
    },
    routeQuery() {
      return this.$route.query
    }
  },

  watch: {
    value() {
      this.onChange()
    }
  },

  mounted() {
    this.debouncedChangeRoute = debounce(this.changeRoute, 500)
    this.debouncedOnChange = debounce(this.onChange, 100)
  },

  methods: {
    handleValues(value) {
      const convertValueIfRequired = val => {
        if (isDate(val)) {
          return val.toISOString()
        }

        return val
      }

      return isArray(value)
        ? value.map(convertValueIfRequired).join(",")
        : convertValueIfRequired(value)
    },
    onChange() {
      const { routeQuery, filterOrSearch, value, handleValues } = this
      const { urlKey } = filterOrSearch
      const shouldRemoveQuery = isArray(value) ? !value.length : !value

      let nextQuery = { ...JSON.parse(JSON.stringify(routeQuery)) }

      const nextValue = handleValues(value)

      if (shouldRemoveQuery) {
        unset(nextQuery, urlKey)
        const [firstKey] = urlKey.split(".")

        if (firstKey.includes(":")) {
          unset(nextQuery, firstKey)
        }
      } else {
        set(nextQuery, urlKey, nextValue)
      }

      this.changeRoute(nextQuery)
    },
    changeRoute(nextQuery) {
      this.$router.push({ path: this.$route.path, query: nextQuery })
    }
  }
}
</script>
