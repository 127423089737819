<template>
  <el-form>
    <div
        class="flex filter-search"
        :class="{'is-single-search': isSingleSearch}">
      <div
          v-if="!isSingleSearch"
          class="flex-column">
        <core-text tag="label">
          Searches and Filters
        </core-text>

        <el-select
            id="search-and-filter"
            v-model="selectedFilter"
            class="filter-selector ghost-element-loading"
            filterable
            size="small"
            :default-first-option="true"
            placeholder="Select Search or Filter">
          <el-option-group
              v-for="group in searchesAndFiltersOptions"
              :key="group.label"
              :label="group.label">
            <el-option
                v-for="item in group.options"
                :key="item.value"
                :label="item.label"
                :value="item.value" />
          </el-option-group>
        </el-select>
      </div>


      <div
          v-if="!loading && !isSingleSearch"
          class="filters-and-searches flex-column">
        <div
            v-for="filterOrSearch of filterTypes"
            :key="filterOrSearch.label">
          <SearchOrFilter
              v-show="selectedFilter === filterOrSearch.urlKey"
              :filter-or-search="filterOrSearch" />
        </div>
      </div>

      <div
          v-else-if="!loading"
          class="filters-and-searches flex-column">
        <SearchOrFilter
            :label="singleSearch.label"
            :filter-or-search="singleSearch" />
      </div>

      <div
          v-if="!selectedFilter && !isSingleSearch"
          class="filters-and-searches flex-column">
        <div class="flex-column field">
          <core-text
              class="info-text"
              hint
              tag="label">
            Select and apply multiple Searches and/or Filters to narrow the results of the table
          </core-text>

          <el-input
              class="ghost-element-loading"
              placeholder="No Searches or Filters selected"
              size="small"
              :disabled="true" />
        </div>
      </div>
    </div>

    <div
        v-if="hasSearches"
        class="active-filters flex items-center">
      <core-text
          label
          bold>
        Active Searches:
      </core-text>

      <SearchOrFilterValueTags
          :on-active-filter-close="onActiveFilterClose"
          :search-or-filters-with-values="searchesWithValues" />
    </div>

    <div
        v-if="hasFilters"
        class="active-filters flex items-center">
      <core-text
          label
          bold>
        Active Filters:
      </core-text>

      <SearchOrFilterValueTags
          query-type="filter"
          :on-active-filter-close="onActiveFilterClose"
          :search-or-filters-with-values="filtersWithValues" />
    </div>
  </el-form>
</template>

<script>
import isArray from "lodash/isArray"
import toNumber from "lodash/toNumber"
import isString from "lodash/isString"
import get from "lodash/get"
import pluralize from "pluralize"
import models from "@/Modules/OdysseyModels"
import SearchOrFilter from "./SearchOrFilter"
import SearchOrFilterValueTags from "./SearchOrFilterValueTags"

export default {
  name: "CoreDataTableSearchesAndFilters",
  filters: {
    pluralize: function(value) {
      return pluralize(value)
    }
  },
  components: { SearchOrFilter, SearchOrFilterValueTags },
  props: {
    value: { type: Object, default: () => {} },
    searchesAndFilters: { type: [Array, Object], default: () => [] },
    onFilterSave: { type: Function, default: () => [] }
  },

  data() {
    return {
      filterTypes: [],
      loading: true,
      selectedFilter: null,
      searchesAndFiltersOptions: [],
      singleSearch: {}
    }
  },

  computed: {
    isSingleSearch() {
      return !isArray(this.searchesAndFilters)
    },
    routeQuery() {
      return this.$route.query
    },
    searchesWithValues() {
      return this.filterTypes.filter(({ queryType, filterValue }) => {
        const hasValue = isArray(filterValue) ? filterValue.length : filterValue

        return queryType === "search" && hasValue
      })
    },
    filtersWithValues() {
      return this.filterTypes.filter(({ queryType, filterValue }) => {
        const hasValue = isArray(filterValue) ? filterValue.length : filterValue

        return queryType === "filter" && hasValue
      })
    },
    hasFilters() {
      return this.filterTypes.filter(({ queryType }) => queryType === "filter").length
    },
    hasSearches() {
      return this.filterTypes.filter(({ queryType }) => queryType === "search").length
    }
  },
  mounted() {
    if (!this.isSingleSearch) {
      this.getFilterTypes()
    } else {
      const filterValue = get(this.routeQuery, this.searchesAndFilters.urlKey)
      this.singleSearch = { ...this.searchesAndFilters, filterValue }
      this.loading = false
    }
  },
  methods: {
    async getFilterTypes() {
      const buildFilterTypes = async (asyncFilterTypes, nextFilterType) => {
        let filterTypes = await asyncFilterTypes

        const { optionsFromEntity: entity, optionShape, options } = nextFilterType

        // Determine the default selected option (if defined)
        const defaultOption = options.find(filterOptions => filterOptions.selectedByDefault)

        if (defaultOption && defaultOption.urlKey) {
          this.selectedFilter = defaultOption.urlKey
        }

        const addOptionValue = option => {
          return { ...option, value: option.urlKey }
        }

        if (entity) {
          await models[entity].api().fetchAll()
          const entityOptionsFilters = models[entity].all().map(optionShape)

          filterTypes = [...filterTypes, ...entityOptionsFilters]

          this.searchesAndFiltersOptions.push({
            ...nextFilterType,
            options: entityOptionsFilters.map(addOptionValue)
          })
        } else {
          filterTypes = [...filterTypes, ...options]

          this.searchesAndFiltersOptions.push({
            ...nextFilterType,
            options: options.map(addOptionValue)
          })

          this.searchesAndFiltersOptions[0].options.find(option => {
            if (option.default) {
              this.selectedFilter = option.value
            }
          })
        }

        return filterTypes
      }

      const handleValueDataType = (value = "", filterType) => {
        const { inputType = "" } = filterType
        const isDate = inputType.includes("date") || inputType.includes("time")
        const isEntity = inputType.includes("entity")
        const isMultiple =
          get(filterType, "props.multiple") || value.includes(",") || inputType.includes("range")

        const handleMultiple = toDataType => {
          const values = value.split(",")

          const removeEmptyString = val => val !== ""

          return values.filter(removeEmptyString).map(toDataType)
        }

        if (isEntity) {
          const singleValue = !isString(value) ? toNumber(value) : null

          return isMultiple ? handleMultiple(toNumber) : singleValue
        }

        if (isDate) {
          return isMultiple ? handleMultiple(d => new Date(d)) : new Date(value)
        }

        return value
      }

      const getValuesFromUrl = filterType => {
        const { urlKey } = filterType
        const value = get(this.routeQuery, urlKey)

        return { ...filterType, value: urlKey, filterValue: handleValueDataType(value, filterType) }
      }

      this.filterTypes = await this.searchesAndFilters.reduce(buildFilterTypes, [])
      this.filterTypes = this.filterTypes.map(getValuesFromUrl)

      this.loading = false
    },
    onActiveFilterClose(searchOrFilter) {
      this.filterTypes = this.filterTypes.map(filterType => {
        if (filterType.urlKey === searchOrFilter.urlKey) {
          const filterValue = isArray(searchOrFilter.filterValue) ? [] : null

          return { ...searchOrFilter, filterValue }
        }

        return filterType
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form {
  padding: 0 !important;
  ::v-deep  .el-form-item {
    margin: 0 !important;
  }
  ::v-deep  .el-form-item__content {
    height: 32px;
    line-height: 32px;
  }
}

::v-deep  .el-cascader {
  display: flex;
}

::v-deep  .el-cascader--small {
  .el-cascader__tags {
    display: flex;
    align-items: center;
  }

  .el-input__inner {
    height: 32px !important;
  }
}

.filter-search:not(.is-single-search) {
  .field ::v-deep  .el-input__inner {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.filter-search {
  padding: var(--padding-m) 0;
  width: fit-content;

  .ghost-element-loading {
    border-radius: 4px;
    &.field {
      min-height: 32px;
    }
  }
}

::v-deep  label.core-text {
  margin-bottom: var(--margin-xs);
}

.field {
  min-width: 400px;
}

.filter-selector ::v-deep  .el-input__inner {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.active-filters {
  padding: var(--padding-m) 0;
  padding-top: 0;
  color: var(--main-primary);
  margin-left: var(--margin-m);
}

.type-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.apply-button {
  text-transform: capitalize;
  margin-left: var(--margin-m);
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
  .field {
    min-width: 50%;
  }
  .info-text {
    overflow: hidden;
    height: 1.7em;
  }
}
</style>
