<template>
  <el-dropdown
      ref="dropdown"
      trigger="click"
      class="table-customiser"
      placement="bottom-start">
    <el-button
        class="ghost-element-loading"
        size="small"
        icon="el-icon-setting">
      Customise Table<i class="el-icon-arrow-down el-icon--right" />
    </el-button>

    <el-dropdown-menu slot="dropdown">
      <div
          class="customiser-container">
        <div class="header-container">
          <CoreHint
              box
              hint-text="
          Reorder columns with drag handle to the left of each column.
          <br><br>
          Show/hide columns with the checkbox.">
            <core-text tag="h3">
              Table Column Customisation
            </core-text>
          </CoreHint>
        </div>

        <div class="actions-toggle-wrapper">
          <core-text
              label
              tag="div">
            Display Action Menu as
          </core-text>
          <div class="switch-wrapper">
            <el-switch
                v-model="actionsMenuAsDropdown"
                active-text="Drop-Down"
                inactive-text="Icon List"
                active-color="#35c58b"
                inactive-color="#409EFF" />
          </div>
          <core-text hint>
            This setting affects all tables.
          </core-text>
        </div>

        <draggable
            v-model="columns"
            class="columns"
            draggable="li"
            group="columns"
            tag="ul">
          <li
              v-for="(col, index) in columns"
              :key="`col-${index}`">
            <div class="options">
              <div class="drag-handle">
                <i
                    class="el-icon-sort" />
              </div>

              <el-checkbox
                  v-model="col.visible"
                  class="checkbox" />

              <strong>{{ col.label }}</strong>
            </div>
          </li>
        </draggable>

        <div class="buttons">
          <el-button
              type="primary"
              size="small"
              @click="onReset">
            Reset
          </el-button>
          <el-button
              type="primary"
              size="small"
              @click="onCancel">
            Cancel
          </el-button>
          <el-button
              type="success"
              size="small"
              icon="el-icon-check"
              @click="onSave">
            Save changes
          </el-button>
        </div>
      </div>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import draggable from "vuedraggable"
export default {
  name: "DataTableCustomiser",
  components: {
    draggable
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    tableName: {
      type: String,
      required: true
    },
    storeCustomColumns: {
      type: Function,
      required: true
    },
    resetCustomColumns: {
      type: Function,
      required: true
    },
    handleCustomisedColumns: {
      type: Function,
      required: true
    },
    showActionsAsDropdown: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      showCustomise: false
    }
  },
  computed: {
    actionsMenuAsDropdown: {
      get() {
        return this.showActionsAsDropdown
      },
      set(val) {
        this.$emit("toggleActionsAsDropdown", val)
      }
    },
    columns: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  },
  methods: {
    closeDropdown() {
      this.$refs.dropdown.hide()
    },
    onReset() {
      this.resetCustomColumns()
      this.closeDropdown()
    },
    onSave() {
      this.storeCustomColumns(this.columns)
      this.closeDropdown()
    },
    onCancel() {
      this.handleCustomisedColumns()
      this.closeDropdown()
    }
  }
}
</script>

<style lang="scss" scoped>
.customiser-container {
  padding: 0 var(--padding-m);
  max-width: 310px;

  .header-container {
    padding-bottom: var(--padding-l);
  }
  .actions-toggle-wrapper {
    margin: 0 0 1em 0;
    padding: 1em 0;
    border-top: 1px solid var(--main-border-color);
    border-bottom: 1px solid var(--main-border-color);

    .switch-wrapper {
      padding: 0.5em 0;

      ::v-deep  .el-switch__label {
        span {
          font-size: 1em !important;
        }
      }
    }
  }

  .buttons {
    padding-top: var(--padding-m);
  }

  .options {
    margin-bottom: var(--margin-s);
    display: flex;

    .drag-handle {
      display: flex;
      justify-content: flex-start;
    }

    i {
      margin-right: var(--margin-s);
      cursor: pointer;
    }

    .checkbox {
      margin-right: var(--margin-s);
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 991px) {
  .table-customiser {
    display: none;
  }
}
</style>
