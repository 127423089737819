import isDate from "lodash/isDate"

const MILITARY = (dateStamp, defaultStamp = "--") => {
  if (!dateStamp) return defaultStamp

  const date = new Date(dateStamp)
  const hours = parseInt(date.getHours(), 10)
  const minutes = parseInt(date.getMinutes(), 10)

  if (minutes < 10) return `${hours}:0${minutes}`

  return `${hours}:${minutes}`
}

const AM_PM = (dateStamp, defaultStamp = "--") => {
  if (!dateStamp) return defaultStamp

  const date = new Date(dateStamp)

  let hours = date.getHours()
  let minutes = date.getMinutes()
  const ampm = hours >= 12 ? "pm" : "am"

  hours %= 12
  hours = hours || 12
  minutes = minutes < 10 ? `0${minutes}` : minutes

  const strTime = `${hours}:${minutes} ${ampm}`

  return strTime
}

const DATE_AND_TIME = (date, LOCALE = "en-AU", defaultStamp = "--") => {
  return isDate(date)
    ? date.toLocaleDateString(LOCALE, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit"
      })
    : defaultStamp
}

const DATE = (date, LOCALE = "en-AU", defaultStamp = "--") => {
  return isDate(date)
    ? date.toLocaleDateString(LOCALE, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      })
    : defaultStamp
}

const formatters = { MILITARY, AM_PM, DATE_AND_TIME, DATE }

const createDateGetter = (dateString, defaultStamp = "--") => {
  const LOCALE = "en-AU"

  const date = {}

  date.RAW = dateString ? new Date(dateString) : null

  date.MILITARY = MILITARY(dateString, defaultStamp)

  date.AM_PM = AM_PM(dateString, defaultStamp)

  date.DATE_AND_TIME = DATE_AND_TIME(date.RAW, LOCALE, defaultStamp)

  date.DATE = DATE(date.RAW, LOCALE, defaultStamp)

  return date
}

export { createDateGetter }

export default formatters
